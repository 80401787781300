.react-datepicker-wrapper{
    background-color: red !important;
    width: 96px !important;
}
.react-datepicker__input-container{
 width: 96px !important;
}
.react-datepicker-ignore-onclickoutside{
    width: 96px !important;
}
.react-datepicker-ignore-onclickoutside:focus-visible{
    outline: none;
}
.react-datepicker__input-container input{
    width: 96px !important;
    cursor: pointer;
}